import React from "react";

import Avatar from "./Avatar";

interface IAvatarGroupProps {
  left?: string;
  right?: string;
  className: String;
}

const AvatarGroup: React.FunctionComponent<IAvatarGroupProps> = ({
  left,
  right,
  className,
}) => {
  return (
    <div className="block m-auto text-center relative ">
      <Avatar
        className={`inline-block -mr-2.5 ${className}`}
        uri={left}
      />
      <Avatar
        className={`inline-block -ml-2.5 ${className}`}
        uri={right}
      />
    </div>
  );
};

export default AvatarGroup;
