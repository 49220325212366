import React from "react";

import { UserIcon } from "@heroicons/react/solid";

interface IAvatar {
  className?: string;
  uri?: string;
}
const Avatar: React.FunctionComponent<IAvatar> = ({
  className,
  uri,
}) => {
  return (
    <>
      {uri ? (
        <img 
          src={uri} 
          className={`avatar ${className || ""}`}
          loading="lazy"
          alt="profile"
      />
      ) : (
        <UserIcon className={`avatar bg-gray-100 text-gray-300 ${className}`} />
      )}
    </>
  );
};

export default Avatar;
