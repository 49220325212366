import React, { useEffect, useState } from "react";


import { PollResponse } from "api/poll";
import PrimaryButton from "components/common/Buttons/Primary";
import BaseInputV2 from "components/common/Inputs/BaseInputV2";
import AvatarGroup from "components/Icons/AvatarGroup";
import { CreatoIcon } from "components/Icons/CreatoIcon";
import TopSection from "components/Sections/Top";
import { Link, PageProps } from "gatsby";
import useGlobalContext from "hooks/common/useGlobalContext";
import usePollUpdater from "hooks/polls/usePollUpdater";
import { AiFillBulb } from "react-icons/ai";

const MIN_BALANCE_REQUIRED = 300;

interface IProps extends PageProps<any, PollResponse, any> {
  params: {
    id: string;
  };
}

const DareCreator: React.FunctionComponent<IProps> = ({
  params,
}) => {
  const { id } = params;
  const { poll } = usePollUpdater({ id });
  const { globalState } = useGlobalContext();
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState(MIN_BALANCE_REQUIRED);
  const [warn, setWarn] = useState(false);
  const [message, setMessage] = useState("");


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(parseInt(e.target.value));
    }
  };

  useEffect(() => {
    const checkAmount = () => {
      if (amount < MIN_BALANCE_REQUIRED) {
        setWarn(true);
        setMessage("Please support at least 300 coins");
      } else {
        setWarn(false);
        setMessage("");
      }
    };
    return () => {
      checkAmount();
    };
  }, [amount]);

  return (
    <div>
      <TopSection
        title="Dare this Creator"
        leftComponent={true}
        rightComponent={<AiFillBulb className="text-success-600" />}
      />
      <div className="p-4 mt-2 shadow-xl rounded-xl">
        <AvatarGroup
          left={poll?.creator?.photoURL!}
          right={globalState.user.photoURL!}
          className={"w-32 h-32"}
        />

        <p>Dare this Creator something</p>
        <BaseInputV2
          type="text"
          maxLength={20}
          value={title}
          placeholder="Text here"
          onChange={handleChange}
        />
        <div className="flex flex-row mt-12 mb-2">
          <span className="w-4">
            <CreatoIcon className="fill-primary-500" aria-hidden="true"/>
          </span>
          <p className="ml-2">Raise your bet! </p>
        </div>
        <BaseInputV2
          type="number"
          placeholder="Text here"
          value={amount}
          onChange={handleAmountChange}
          error={warn}
          message={message}
          step="1"
        />
        <p className="mt-8 text-neutral-800">
          {`Most people support with ${MIN_BALANCE_REQUIRED} coins. The amount is fully refundable if
          the creator does not accept your request`}
        </p>
      </div>

      <Link
        to="/dare/confirmation"
        state={{
          poll: poll,
          input: {
            title,
            amount
          }
        }}
      >
        <PrimaryButton className="w-full mt-10 px-3 py-2" disabled={title.length === 0}>Dare creator</PrimaryButton>
      </Link>
    </div>
  );
};

export default DareCreator;
