import React, { ReactNode, useCallback } from "react";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import PrimaryButton from "components/common/Buttons/Primary";
import { navigate } from "gatsby-link";
import { classNames } from "helper/style";

interface ITopSectionProps {
  title: String;
  leftComponent?: boolean | ReactNode;
  rightComponent?: JSX.Element;
}

const TopSection: React.FunctionComponent<ITopSectionProps> = ({
  title,
  leftComponent,
  rightComponent,
  children,
}) => {
  const back = useCallback(() => {
    navigate(-1)
  }, []);

  return (
    <div
      className={`w-full flex space-evenly items-center`}
    >
      {
        children ?
         React.Children.map(children, (child, i) => (
          <div className={
            classNames(
              i === 1 ? "flex-shrink-0" : "flex-[0.2]"
            )
          }>
            {
              child ? child : 
                (i === 0 && 
                  (leftComponent ||
                  <PrimaryButton className={"btn-empty"} onClick={back}>
                    <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                  </PrimaryButton>)) ||
                (i === 1 &&
                <h5 className="text-primary-500 flex-1 text-center capitalize">{title}</h5>) ||
                (i === 2 &&
                  (rightComponent &&
                    React.cloneElement(
                      rightComponent as JSX.Element, 
                      {className: classNames("ml-auto mr-9", rightComponent.props.className
                      )}
                    )
                  )
                )
            }
          </div>
        )) :
        <>
          <div className="flex-[0.2]">
            {
              typeof leftComponent === 'boolean' ?
              <PrimaryButton className={"btn-empty"} onClick={back}>
                <ChevronLeftIcon className="h-6 w-6" />
              </PrimaryButton> :
              leftComponent
            }
          </div>
          <h5 className="text-primary-500 flex-[0.6] text-center">{title}</h5>
          <div className="flex-[0.2]">
            {
              rightComponent ? 
                React.cloneElement(
                  rightComponent as JSX.Element, 
                  {className: classNames("ml-auto mr-9", rightComponent.props.className
                  )}) : 
              <></>
            }
          </div>
        </>
      }
    </div>
  );
};

export default TopSection;
