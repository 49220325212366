import { useCallback, useEffect, useMemo, useState } from "react";

import { PollResponse, getPoll } from "api/poll";
import dayjs from "dayjs";
import { ID } from "types/api";

import useLoading from "../common/useLoading";

import "dayjs/plugin/utc";

var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

export interface PollUpdaterHookInterface {
  id: ID;
  state?: PollResponse;
  onCompleted?: (data: PollResponse) => void;
}

const usePollUpdater = (props: PollUpdaterHookInterface) => {
  const { id, state, onCompleted = () => {} } = props;
  const [poll, setPoll] = useState(state);
  const { isOpen, setLoading } = useLoading(true);

  const getOnePoll = useCallback(async () => {
    try {
      const response = await getPoll(id);
      const now = new Date().valueOf();
      const deadline = new Date(response?.data.data.deadline).valueOf();
      const expired = deadline > now;
      const poll = {
        ...response?.data.data,
        expired,
      };

      setPoll(poll);
      onCompleted(poll);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  },[id, onCompleted, setLoading])

  useEffect(() => {      
    getOnePoll();
  }, []);

  return useMemo(
    () => ({
      poll,
      loading: isOpen
    }),

    [poll, isOpen]
  );
};

export default usePollUpdater;
