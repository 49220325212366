import React, { useEffect, useState } from "react";

import "../../../styles/global.css";
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { classNames } from "helper/style";
import { AiOutlineExclamation } from "react-icons/ai";

type IReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface IBaseInputProps extends IReactInputProps {
  label?: string;
  error?: boolean;
  message?: string;
  iconClassName?: string;
}

const BaseInputV2: React.FunctionComponent<IBaseInputProps> = ({
  label,
  error,
  message,
  iconClassName,
  id,
  name,
  value,
  required,
  disabled,
  className,
  onChange: onNewChange,
  children,
  ...props
}) => {
  const [state, setState] = useState<IBaseInputProps["value"]>();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    setState(target.value);
    onNewChange && onNewChange(e);
  }

  useEffect(() => {
    setState(value || "");
  }, [value])

  return (
    <div>
      <label htmlFor={name} className={
        classNames(
          required ? "after:content-['*'] after:ml-0.5 after:text-primary-600" : "",
          "block text-sm font-medium text-gray-700"
        )
      }>
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={classNames(
            "block italic w-full p-2 border rounded-lg outline-none hover:border-primary-500 hover:text-primary-500 hover:placeholder-primary-500 focus:placeholder-shades-100 focus:text-shades-100  focus:outline-none", 
            error ? "border-secondary-700 text-red-900 placeholder-red-300 hover:border-secondary-700 focus:ring-red-500 focus:border-red-500" : "border-shades-100",
            disabled ? "bg-neutral-100 placeholder-neutral-800 border-none hover:placeholder-neutral-800" : "border-shades-100",
            className ? className : "",
          )}
          disabled={disabled}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          {...props}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {error && <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />}
          {children}
        </div>
      </div>
      {message && 
        <p className={classNames(
          error ? "text-secondary-700" : "text-shades-100"
        )} id={`${name}-error`}>
          {message}
        </p>
      }
      {props.type === "text" && props?.maxLength && (
        <p
          className="italic text-neutral-400 text-right"
        >{`(${state?.toString().length ?? 0}/${props?.maxLength} words)`}</p>
      )}
    </div>
  )
};

export default BaseInputV2;
